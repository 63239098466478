import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { withAttrs } from '../../../utils'
import { mediaQueries } from '../../../theme'
import Link from '../../shared/link'

const moveToRight = keyframes`
  0% {
    transform: translate3d(-50%,0,0);
  }

  100% {
    transform: translateZ(0);
  }
`

const Container = styled.div`
  background-image: linear-gradient(
    135deg,
    rgba(255, 216, 76, 50%) 0%,
    rgba(212, 161, 214, 50%) 52.85%,
    rgba(129, 212, 255, 50%) 100%
  );
  overflow: hidden;
`

const MainText = styled.span``

const LineLink = styled(withAttrs(Link, { variant: 'nav' }))`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  min-width: 200vw;
  width: max-content;
  min-height: 7.5rem;
  padding: ${p => p.theme.space[11]} 0;
  font-size: ${p => p.theme.fontSizes[5]};
  line-height: 1.2;
  font-weight: ${p => p.theme.fontWeights.bold};
  color: ${p => p.theme.colors.black[1]};
  transform: translate3d(-50%, 0, 0);
  animation: ${moveToRight} 60s infinite linear;

  &:hover,
  &:focus {
    text-decoration: none;

    ${MainText} {
      text-decoration: underline;
    }
  }

  ${mediaQueries.lg} {
    padding: ${p => p.theme.space[14]} 0;
    font-size: ${p => p.theme.fontSizes[9]};
  }
`

const Arrow = styled.span`
  padding: 0 ${p => p.theme.space[5]};
`

const Text = styled.p`
  display: flex;
  justify-content: space-around;
  margin-bottom: 0;
  min-width: 50%;
  margin: 0;
`

const RunningLine = ({ link = '/start', text, className }) => {
  if (!Array.isArray(text)) {
    text = [text]
  }

  const getTextArray = (len = 8) => {
    const result = []
    while (result.length < len) {
      result.push(...text)
    }
    return result
  }

  const renderLine = () => (
    <Text>
      {getTextArray().map((child, i) => (
        <Fragment key={i}>
          <MainText>{child}</MainText> <Arrow>→</Arrow>
        </Fragment>
      ))}
    </Text>
  )

  return (
    <Container className={className}>
      <LineLink to={link}>
        {renderLine()}
        {renderLine()}
      </LineLink>
    </Container>
  )
}

export default RunningLine
